import React from 'react';
import { Divider } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { LinkComponent } from '../Reusable';
import { getImprintLink, getPrivacyPolicyLink, getTermsLink, getCloudLink } from '../../helper/BaseActions';
import {getLocale} from '../../helper/CountryLanguages';
import Grid from '@mui/material/Unstable_Grid2';
import "./Footer.css"

export function Footer() {
    const { lng } = getLocale();
    const { t } = useTranslation();

    const version = process.env.REACT_APP_VERSION;
    return (
        <div>
            <Divider variant="middle" style={{ backgroundColor: "#e0e0e0", marginLeft: "0px", marginRight: "0px" }} />
                <Grid container justifyContent="space-between" alignItems="center" className="footer-companyInitials">
                    <Grid pl={3}>
                        <p>&copy; {(new Date().getFullYear())} Festo Corporation. All Rights Reserved  ({version})</p>
                    </Grid>
                    <Grid pr={3}>
                        <LinkComponent
                            newTab
                            link={getImprintLink(lng)}
                            innerComponent={t("Imprint")}
                        />
                        <LinkComponent
                            newTab
                            link={getPrivacyPolicyLink(lng)}
                            innerComponent={t("DataPrivacy")}
                        />
                        <LinkComponent
                            newTab
                            link={getTermsLink(lng)}
                            innerComponent={t("TermsAndConditionsOfSale")}
                        />
                        <LinkComponent
                            newTab
                            link={getCloudLink(lng)}
                            innerComponent={t("CloudServices")}
                        />
                        <a onClick={() => window?.Didomi?.preferences?.show()}>Cookie Settings</a>
                        {window.location.hostname == "energysavingservices.festo.com.cn" &&
                            <LinkComponent
                                newTab
                                link="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502006722"
                                innerComponent="沪公网安备 31011502006722号"
                            />
                        }
                    </Grid>
                </Grid>
        </div>
    );

}
