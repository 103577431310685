import { checkIsNotNullOrWhitespace, checkIsNumber } from '../../helper/Validator';
import * as Fmt from '../../helper/Formatters'

export const ProjectModelFactory = (t) => ({
    projectId: { name: "ProjectId", label: "", idkey: "PS-projectId", value: "", isValid: true, validationError: "", validators: [], headerName: t('ProjectNo') },
    projectName: { label: t("ProjectName"), idkey: "PS-projectName", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    isCompressedAirEnergyEfficiencyPreAuditSelected: { label: t("CompressedAirEnergyEfficiencyPreAudit"), idkey: "PS-isCompressedAirEnergyEfficiencyPreAuditSelected", value: false, isValid: true, validationError: "", validators: [] },
    isAnalysisOfTheCompressedAirGenerationSelected: { label: t("AnalysisOfTheCompressedAirGeneration"), idkey: "PS-isAnalysisOfTheCompressedAirGenerationSelected", value: false, isValid: true, validationError: "", validators: [] },
    isCompressedAirQualityAnalysisSelected: { label: t("CompressedAirQualityAnalysis"), idkey: "PS-isCompressedAirQualityAnalysisSelected", value: false, isValid: true, validationError: "", validators: [] },
    isAirNetworkAnalysisSelected: { label: t("AirNetworkAnalysis"), idkey: "PS-isAirNetworkAnalysisSelected", value: false, isValid: true, validationError: "", validators: [] },
    isMachineAnalysisForEnergyEfficiencySelected: { label: t("MachineAnalysisForEnergyEfficiency"), idkey: "PS-isMachineAnalysisForEnergyEfficiencySelected", value: false, isValid: true, validationError: "", validators: [] },
    isLeakageDetectionAndDocumentationSelected: { label: t("LeakageDetectionAndDocumentation"), idkey: "PS-isLeakageDetectionAndDocumentationSelected", value: false, isValid: true, validationError: "", validators: [] },
    customer: { name: "Customer", label: t("ChooseCustomer"), idkey: "PS-customer", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], headerName: t('CustomerName') },
    freeTextField: { label: t("FreeTextField"), idkey: "PS-freeTextField", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    sapOrderNo: { label: t("SAPOrderNo"), idkey: "PS-sapOrderNo", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace] },
    estimatedLeakageDetectionDays: { label: t("EstimatedLeakageDetectionDays"), idkey: "PS-estimatedLeakageDetectionDays", value: "", isValid: true, validationError: "", validators: [] },
    licenseTypeId: { label: t("LicenseTypeId"), idkey: "PS-LicenseTypeSelectId", value: 0, isValid: true, validationError: "", validators: [(value) => value != 0] },
    travelTime: { label: t("TravelTime") + Fmt.getUnitInBracket("hours", t), idkey: "PS-travelTime", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    travelDistance: { label: t("TravelDistance") + Fmt.getUnitInBracket("unitKmMile", t), idkey: "PS-travelDistance", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    travelCostsKnown: { label: t("TotalTravelCostsAvailable"), idkey: "PS-travelCostsKnown", value: false, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    travelLumpSum: { label: t("LumpSum") + Fmt.getCurrency(), idkey: "PS-travelLumpSum", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    travelTimeCosts: { label: t("TravelTimeCosts") + Fmt.getCurrency(), idkey: "PS-travelTimeCosts", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
    travelCosts: { label: t("TravelCosts") + Fmt.getCurrency(), idkey: "PS-travelCosts", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace, checkIsNumber] },
})

export const ProjectListFilterModelFactory = (t) => ({
    executorName: { name: "ExecutorName", idkey: "PS-executorName", headerName: t('ExecutorName') },
    companyName: { name: "CompanyName", idkey: "PS-companyName", headerName: t('CompanyName') },
    city: { name: "City", idkey: "PS-city", headerName: t('CustomerCity') },
    country: { name: "Country", idkey: "PS-country", headerName: t('CustomerCountry') },
    serviceType: { name: "ServiceType", idkey: "PS-serviceType", headerName: t('ServiceType'), sortable: false },
    email: { name: "Email", idkey: "PS-email", headerName: t('ContactPerson') },
    createdDate: { name: "CreatedDate", idkey: "PS-createdDate", headerName: t('CreatedDate') },
    changedDate: { name: "ChangedDate", idkey: "PS-changedDate", headerName: t('LastChangeDate') },
    createdUser: { name: "CreatedUser", idkey: "PS-createdUser", headerName: t('CreatedUser') },
    inquiryId:  { name: "InquiryId", idkey: "PS-inquiryId", headerName: t('InquiryId') },
    status: { name: "Status", idkey: "PS-status", headerName: t('Status') },
    requestNo: { name: "Id", idkey: "PS-id", headerName: t('RequestNo') },
})

const travelValidators = [checkIsNotNullOrWhitespace, checkIsNumber];

export const setTravelValidators = model => {
    let value = model.travelCostsKnown.value;
    model.travelTime.validators = value ? [] : travelValidators;
    model.travelDistance.validators = value ? [] : travelValidators;
    model.travelLumpSum.validators = value ? travelValidators : [];
    model.travelTimeCosts.validators = value ? travelValidators : [];
    model.travelCosts.validators = value ? travelValidators : [];
    return model;
}