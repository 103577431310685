import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Tooltip, Link, TablePagination } from '@mui/material';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { SimpleCheckbox, TableHeadCells, TableHeadingContent, ModalComponent } from '../../Reusable/';
import { API } from '../../../helper/ApiHelper';
import * as Consts from '../../../helper/ConstantRepository';
import { OpenGlobalSnackbar } from '../../../helper/GlobalVariables';
import { ExecutorStore, UserStore } from '../../../store/'
import { FilterModel } from "../../Reusable/TableHeadingContent/GlobalFilterModel"
import { ProjectModelFactory, ProjectListFilterModelFactory } from '../../ProjectSettings/ProjectModel';
import { ProjectsListStore } from './ProjectsListStore';
import { toLocalDateString } from '../../../helper/DateHelper'

export default function Projects(props) {
    const { setIsProjectListActive } = props;

    var projectUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "project-management";

    const history = useHistory();
    const { t } = useTranslation();

    const [data, setData] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = React.useState(0);
    const [checkedArrayProject, setCheckedArrayProject] = useState([]);
    const [executorId, setExecutorId] = useState(null)
    const [user, setUser] = useState({});
    const [modalModel, setModalModel] = useState({
        open: false,
        message: ""
    });

    const projectFilterModel = { ...ProjectModelFactory(t), ...ProjectListFilterModelFactory(t) };

    useEffect(() => {
        setIsProjectListActive(true);
        setCheckedArrayProject([]);

        var execSub = ExecutorStore.executorSelection.subscribe(executorId => {
            if (executorId != Consts.ExecutorSelectionNotInitialized && executorId != Consts.ExecutorNotSelected) {
                setExecutorId(executorId)
                fetchProjectData(executorId);
            }
        })

        let userSub = UserStore.user.subscribe(user => {
            setUser(user)
        })

        const projectsListSub = ProjectsListStore.rowsPerPage.subscribe(x => setRowsPerPage(x));

        return () => {
            execSub.unsubscribe();
            userSub.unsubscribe();
            projectsListSub.unsubscribe();
        }
    }, [])

    const fetchProjectData = async (executorId) => {
        API.get(`${projectUrl}/projects/${executorId}`).then(result => {
            var res = JSON.parse(result);
            setData(res);
            setFilterData(res);
            setDataCount(res.length / rowsPerPage > Number.parseInt(res.length / rowsPerPage) ? Number.parseInt(res.length / rowsPerPage) + 1 : Number.parseInt(res.length / rowsPerPage));
        })
    };

    const handleChangePagePr = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPagePr = (event) => {
        ProjectsListStore.rowsPerPage.next(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getProjectLink = id => `/project/${id}/GI`

    const filterFunctionPr = (tempdata) => {
        var tempDataForFilter = tempdata == null ? [] : tempdata;
        setData(tempDataForFilter);
        setDataCount(tempDataForFilter.length / rowsPerPage > Number.parseInt(tempDataForFilter.length / rowsPerPage) ? Number.parseInt(tempDataForFilter.length / rowsPerPage) + 1 : Number.parseInt(tempDataForFilter.length / rowsPerPage));
        setPage(0);
    }

    //delete project

    const clickDeleteProject = () => {
        if (checkedArrayProject == undefined || checkedArrayProject.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModalProject();
    }

    const clickDeleteOpenModalProject = () => {
        modalModel.open = true;
        modalModel.okText = t('Yes');
        modalModel.cancelText = t('No');
        modalModel.message = t('DeleteSelectedRowsQuestion');
        modalModel.clickOk = deleteProject;
        setModalModel({ ...modalModel });
    }

    const handleCheckProject = (checkIndex) => {
        let checkedIndex = checkedArrayProject.indexOf(checkIndex);
        if (checkedIndex < 0)
            checkedArrayProject.push(checkIndex);
        else
            checkedArrayProject.splice(checkedIndex, 1);

        checkedArrayProject.sort(function (a, b) { return b - a });
        setCheckedArrayProject(checkedArrayProject);
    }

    const isProjectCheckedForDeletion = id => {
        let checkedIndex = checkedArrayProject.indexOf(id);
        if (checkedIndex < 0)
            return false;
        else
            return true;
    }

    const deleteProject = async () => {
        await API.post(projectUrl + `/projects/${executorId}/delete`, checkedArrayProject);
        setData(data.filter(x => !checkedArrayProject.includes(x.Id)));
        setCheckedArrayProject([]);
        OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
    }

    const canDeleteProject = () => executorId > 0 && (user?.userRights?.isSuperAdmin || user?.userRights?.isExecutorAdmin);

    //delete project end

    const tableStyles = [
        { width: "115px !important" },
        { width: "115px !important" },
        { width: "115px !important" },
        { width: "115px !important" },
        { width: "115px !important" },
        { width: "60px !important" },
        { width: "115px !important" },
        { width: "115px !important" },
        { width: "115px !important" },
        { width: "115px !important" },
        { width: "115px !important" },
        { width: "115px !important" },
        { width: "115px !important" },
    ]

    return (
        <Grid container>
            <ModalComponent model={modalModel} />
            <Grid item style={{ margin: "20px 0 0 20px" }}>
                <TableHeadingContent
                    clickDelete={canDeleteProject() && clickDeleteProject}
                    defaultData={data}
                    filterData={filterData}
                    onFilterFunction={filterFunctionPr}
                    filterModel={FilterModel.ProjectOverview}
                    tableId={"ProjectOverview"}
                />
            </Grid>
            <Grid item>
                <TableContainer >
                    <Table >
                        <TableHead>
                            <TableRow >
                                {canDeleteProject() && <TableCell width="1%" />}
                                <TableHeadCells
                                    model={projectFilterModel}
                                    propertyList={["projectId", "executorName", "customer", "companyName", "city", "country", "serviceType", "email", "createdDate", "createdUser", "inquiryId", "changedDate", "status"]}
                                    tableName="ProjectOverview"
                                    data={data}
                                    setData={setData}
                                    defaultSortProperty="ChangedDate"
                                    defaultSortOrderAscending={false}
                                    styleList={tableStyles}
                                />
                            </TableRow >
                        </TableHead >
                        <TableBody>
                            {data && data.length > 0 &&
                                data.slice(page * rowsPerPage, rowsPerPage + (page * rowsPerPage)).map((item, index) =>
                                    <TableRow className="table-body-row" key={index} id={"tr_Project-TableRow" + index} >
                                        {canDeleteProject() &&
                                            <TableCell>
                                                <SimpleCheckbox
                                                    id={'cb_Request-checkbox' + index}
                                                    key={Math.random()}
                                                    defaultChecked={isProjectCheckedForDeletion(item.Id)}
                                                    color="default"
                                                    onChange={() => handleCheckProject(item.Id)}
                                                />
                                            </TableCell>
                                        }
                                        <TableCell id={"lnk_Project-ProjectNr" + index}>
                                            <Link
                                                href={getProjectLink(item.Id)}
                                                onClick={(e) => { e.preventDefault(); history.push(getProjectLink(item.Id)) }}
                                                underline="hover"
                                            >
                                                {item.ProjectId}
                                            </Link>
                                        </TableCell>
                                        <TableCell id={"td_Project-ExecutorName" + index} className="word-break-all">{item.ExecutorName}</TableCell>
                                        <TableCell id={"td_Project-Customer" + index} className="word-break-all">{item.Customer}</TableCell>
                                        <TableCell id={"td_Project-CompanyName" + index} className="word-break-all">{item.CompanyName}</TableCell>
                                        <TableCell id={"td_Project-City" + index} className="word-break-all">{item.City}</TableCell>
                                        <TableCell id={"td_Project-Country" + index} className="word-break-all">{item.Country} </TableCell>
                                        <TableCell id={"td_Project-ServiceType" + index} className="service-type">{item.IsCompressedAirEnergyEfficiencyPreAuditSelected == 1 ? <Tooltip title={t("PreAudit")}><img src={"/pictures/CompressedAirEnergyEfficiencyPreAudit.png"} alt="" /></Tooltip> : null}
                                            {item.IsAnalysisOfTheCompressedAirGenerationSelected == 1 ? <Tooltip title={t('CompressedAirGeneration')}><img src={"/pictures/AnalysisOfTheCompressedAirGeneration.png"} alt="" /></Tooltip> : null}
                                            {item.IsCompressedAirQualityAnalysisSelected == 1 ? <Tooltip title={t('AirQuality')}><img src={"/pictures/CompressedAirQualityAnalysis.png"} className="quality" alt="" /></Tooltip> : null}
                                            {item.IsMachineAnalysisForEnergyEfficiencySelected == 1 ? <Tooltip title={t('MA/CACTabName')}><img src={"/pictures/MachineAnalysisForEnergyEfficiency.png"} alt="" /></Tooltip> : null}
                                            {item.IsAirNetworkAnalysisSelected == 1 ? <Tooltip title={t('AirNetwork')}><img src={"/pictures/AirNetworkicon.png"} alt="" /></Tooltip> : null}
                                            {item.IsCompressedAirConsumtionAnalysisSelected == 1 ? <Tooltip title={t("AirConsumption")}><img src={"/pictures/CompressedAirConsumtionAnalysis.png"} alt="" /></Tooltip> : null}
                                            {item.IsLeakageDetectionAndDocumentationSelected == 1 ? <Tooltip title={t('LeakageAnalysis')}><img src={"/pictures/LeakageDetectionAndDocumentation.png"} alt="" /></Tooltip> : null}
                                            {item.IsLeakageDetectionAndDocumentationAndRepairSelected == 1 ? <Tooltip title={t('LeakageDetectionAndDocumentation')}><img src={"/pictures/LeakageDetectionAndDocumentationAndRepair.png"} alt="" /></Tooltip> : null}</TableCell>
                                        <TableCell id={"td_Project-Email" + index} className="word-break-all">{item.Email}</TableCell>
                                        <TableCell id={"td_Project-CreatedDate" + index}>{toLocalDateString(item.CreatedDate)}</TableCell>
                                        <TableCell id={"td_Project-CreatedUser" + index} className="word-break-all">{item.CreatedUser}</TableCell>
                                        <TableCell id={"td_Project-InquiryId" + index} className="word-break-all">{item.InquiryId > 0 ? item.InquiryId : ''}</TableCell>
                                        <TableCell id={"td_Project-ChangedDate" + index}>{toLocalDateString(item.ChangedDate)}</TableCell>
                                        <TableCell id={"td_Project-Status" + index}>{t(item.Status)}</TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableError executorId={executorId} data={data} header="NoProjectsFound" />
                {dataCount > 0 &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={data && data.length > 0 ? data.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePagePr}
                        onRowsPerPageChange={handleChangeRowsPerPagePr}
                    />
                }
            </Grid>
        </Grid>
    );

}
function TableError({ executorId, data, header }) {
    const { t } = useTranslation();
    return (
        <div>
            {
                data && data.length == 0 ?
                    <Grid container justifyContent="center" alignItems="center" direction="column" className="Table_error_container">
                        {executorId == 0 ?
                            <React.Fragment>
                                <SupervisedUserCircleIcon />
                                <h1>{t("Executorisnotselected")}</h1>
                                <h3>{t("Selectexecutorinpopup")}</h3>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="error_svg_circle">
                                    <ListRoundedIcon />
                                </div>
                                <h2>{t(header)}</h2>
                            </React.Fragment>
                        }
                    </Grid>
                    : null
            }
        </div>
    )
}