import React, { useState, useEffect } from 'react';
import { useHistory, Switch } from "react-router-dom";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { useTranslation } from 'react-i18next';
import { Grid, Container, AppBar, Tabs, Tab } from '@mui/material';
import * as Roles from '../../helper/AuthorizationHelper/AuthConstatants';
//@ts-expect-error not converted yet
import TopContentContainer from '../TopContentConainer/TopContentContainer';
//@ts-expect-error not converted yet
import LandingPageView from '../LandingPageView/LandingPageView';
//@ts-expect-error not converted yet
import Projects from './Projects/Projects';
//@ts-expect-error not converted yet
import Requests from './Requests/Requests';
import "./ProjectsAndRequestsLists.css";

interface IProps {
    handleSideBar: (show: boolean, iconHide?: boolean) => void;
}

export function ProjectsAndRequestsLists(props: IProps) {
    const history = useHistory();
    const { t } = useTranslation();

    const [isProjectListActive, setIsProjectListActive] = useState(true);

    useEffect(() => {
        props.handleSideBar(true);
    }, [])

    const clickRequestOverview = () => {
        history.push(`/projectsandrequests/requests`);
    }

    const clickProjectOverview = () => {
        history.push(`/projectsandrequests/projects`);
    }

    return (
        <div className="projects">
            <div className="projectMenu">
                <Container className="projectMenu-body">
                    <TopContentContainer ContentComponent={<LandingPageView />}></TopContentContainer>
                </Container>
                <Container className="projectMenu-nav">
                    <AppBar position="static" color="default" square={false}>
                        <Tabs id="tabs_Container"
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs"
                        >
                            <Tab
                                label={t('ProjectOverview')}
                                id="btn_Project-OverviewButton"
                                className={`project-tab-button ${isProjectListActive === true ? "active-projectMenu-tab" : ""}`}
                                onClick={clickProjectOverview}
                            />
                            <Tab
                                label={t('RequestOverview')}
                                id="btn_Project-RequestButton"
                                className={`project-tab-button ${isProjectListActive === false ? "active-projectMenu-tab" : ""}`}
                                onClick={clickRequestOverview}
                            />
                        </Tabs>
                    </AppBar>
                </Container>
                <Container style={{backgroundColor: "#f2f3f5", paddingBottom: "20px"}}>
                    <Grid container className="projectView-content">
                        <Switch>
                            <PrivateRoute
                                globalContentId={'request-list-page'}
                                path={`/projectsandrequests/requests`}
                                component={Requests}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                                setIsProjectListActive={setIsProjectListActive}
                            />
                            <PrivateRoute
                                globalContentId={'project-list-page'}
                                path={[`/projectsandrequests/projects`, `/projectsandrequests`]}
                                component={Projects}
                                allowedRoles={[Roles.SuperAdmin, Roles.AccountAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                                setIsProjectListActive={setIsProjectListActive}
                            />
                        </Switch>
                    </Grid>
                </Container>
            </div>
        </div >
    );

}
