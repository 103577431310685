import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Grid, TextareaAutosize, Tooltip } from '@mui/material';
import { ButtonComponent, HeadingComponent } from '../../../Reusable';
import { API } from '../../../../helper/ApiHelper';
import { IEmailTemplate, IEmailTemplateModel, EmailTemplateFactory } from './EmailTemplatesModel';
import { OpenGlobalSnackbar } from '../../../../helper/GlobalVariables';
// @ts-expect-error not converted yet
import * as Mapper from '../../../../helper/Mappers';
import { LinkBubbleMenu, LinkBubbleMenuHandler, MenuButtonBold, MenuButtonEditLink, MenuButtonItalic, MenuButtonRedo, MenuButtonStrikethrough, MenuButtonUnderline, MenuButtonUndo, MenuControlsContainer, MenuDivider, MenuSelectHeading, MenuSelectTextAlign, RichTextEditor, RichTextEditorRef, TableBubbleMenu } from 'mui-tiptap';
import StarterKit from "@tiptap/starter-kit";
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { StyledButton } from '../../../Reusable/ListPreviousNext/ListPreviousNext';
import HardBreak from '@tiptap/extension-hard-break';
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import Strike from '@tiptap/extension-strike';
import Link from '@tiptap/extension-link'

export default function EmailTemplates() {
    const { t } = useTranslation();
    const templateUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "email-template";
    const [templates, setTemplates] = useState<IEmailTemplate[]>([]);
    const [templateIndex, setTemplateIndex] = useState<number>(0);
    const [model, setModel] = useState<IEmailTemplateModel>(EmailTemplateFactory(t));
    const rteRef = useRef<RichTextEditorRef>(null);

    useEffect(() => {
        loadTemplateEmail();
    }, []);

    const loadTemplateEmail = () => {
        API.get(templateUrl).then((resp: IEmailTemplate[]) => {

            model.id.value = resp[templateIndex].id;
            model.emailBody.value = resp[templateIndex].emailBody;
            model.emailDescription.value = resp[templateIndex].emailDescription;
            model.emailSubject.value = resp[templateIndex].emailSubject;

            rteRef.current?.editor?.commands.setContent(model.emailBody.value);
            setTemplates(resp);
            setModel(model)
        });
    }

    const go = (isPrev: boolean) => {
        let cId = templateIndex;
        const nextId = isPrev ? --cId : ++cId;
        const tempModel = EmailTemplateFactory(t);

        tempModel.id.value = templates[nextId].id;
        tempModel.emailBody.value = templates[nextId].emailBody;
        tempModel.emailDescription.value = templates[nextId].emailDescription;
        tempModel.emailSubject.value = templates[nextId].emailSubject;

        setModel(tempModel);
        setTemplateIndex(nextId);
    }

    const update = async () => {
        const postModel = Mapper.extractValuesFromModelRecursive(model);
        await API.post(templateUrl, postModel);
        OpenGlobalSnackbar("success", t("SaveSuccessful"));
    }

    return (
        <Grid container spacing={3} direction="row">
            <Grid item xs={12}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Tooltip title={t("Previous")}>
                        <StyledButton disabled={templateIndex < 1} onClick={() => go(true)} id='next-template'>
                            <NavigateBefore />
                        </StyledButton>
                    </Tooltip>
                    <Tooltip title={t("Next")}>
                        <StyledButton disabled={templateIndex >= templates.length - 1} onClick={() => go(false)} id='previous-template'>
                            <NavigateNext />
                        </StyledButton>
                    </Tooltip>
                </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
                <HeadingComponent value={t("EmailDescription")} size="h6" />
            </Grid>
            <Grid item xs={12}>
                <TextareaAutosize id="textarea_email-description" value={model.emailDescription.value} minRows="1"
                    onChange={e => Mapper.updateModel("emailDescription", e.target.value, model, setModel)}
                />
            </Grid>
            <Grid item xs={12}>
                <HeadingComponent value={t("EmailSubject")} size="h6" />
            </Grid>
            <Grid item xs={12}>
                <TextareaAutosize id="textarea_email-subject" value={model.emailSubject.value} minRows="1"
                    onChange={e => Mapper.updateModel("emailSubject", e.target.value, model, setModel)}
                />
            </Grid>
            <Grid item xs={12}>
                <HeadingComponent value={t("EmailBody")} size="h6" />
            </Grid>
            <Grid item xs={12}>
                <RichTextEditor
                    ref={rteRef}
                    extensions={[
                        StarterKit,
                        LinkBubbleMenuHandler,
                        Underline,
                        Strike,
                        Link,
                        TextAlign.configure({
                            types: ["heading", "paragraph"],
                        }),
                        HardBreak.extend({
                            addKeyboardShortcuts() {
                                return {
                                    Enter: () => this.editor.commands.setHardBreak()
                                }
                            }
                        })
                    ]}
                    renderControls={() => (
                        <MenuControlsContainer>
                            <MenuSelectHeading />
                            <MenuDivider />
                            <MenuButtonBold />
                            <MenuButtonItalic />
                            <MenuButtonUnderline />
                            <MenuButtonStrikethrough />
                            <MenuDivider />
                            <MenuButtonEditLink />
                            <MenuDivider />
                            <MenuSelectTextAlign />
                            <MenuDivider />
                            <MenuButtonUndo />
                            <MenuButtonRedo />
                        </MenuControlsContainer>
                    )}
                    RichTextFieldProps={{
                        variant: "outlined",
                        MenuBarProps: {
                            hide: false,
                            disableSticky: true,
                        },
                    }}
                    onBlur={e => { Mapper.updateModel("emailBody", e.editor.getHTML(), model, setModel);}}>
                    {() => (
                        <>
                            <LinkBubbleMenu />
                            <TableBubbleMenu />
                        </>
                    )}
                </RichTextEditor>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <ButtonComponent value={t("SaveEmail")} color="primary" onChange={update} id="save-template" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
