import { BehaviorSubject } from "rxjs";
import { ExecutorStore } from './AccountStore';
import { API } from '../helper/ApiHelper';
import { AuthContextProps } from "react-oidc-context";

export interface IUser {
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    accountType?: string;
    userRights?: IUserRights;
}

export interface IUserRights {
    isSuperAdmin?: boolean;
    isTechnician?: boolean;
    isReader?: boolean;
    isEditor?: boolean;
    isExecutorAdmin?: boolean;
}

const user = new BehaviorSubject<IUser>({});
const token = new BehaviorSubject<string>("");
const tokenExpiryDate = new BehaviorSubject(Date.now());
const userLoggedIn = new BehaviorSubject(false);
const getUserUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "uum/users";
let loginTimeout: NodeJS.Timeout | string | number | undefined = undefined;
let loginExpiryCheckTimeout: NodeJS.Timeout | string | number | undefined = undefined;

const setTokenRefresh = (oidcContext: AuthContextProps) => {
    clearTimeout(loginExpiryCheckTimeout);
    loginExpiryCheckTimeout = setTimeout(() => setTokenRefresh(oidcContext), 5000);

    if (!document.hidden && token.value != undefined && token.value.length > 0 && Date.now() > tokenExpiryDate.value)
        checkAuth(oidcContext);
}

const checkAuth = async (oidcContext: AuthContextProps) => {
    clearTimeout(loginTimeout);
    loginTimeout = setTimeout(() => checkAuth(oidcContext), 3000);
    const isTokenStillValid = oidcContext?.user?.expires_at != undefined && oidcContext?.user?.expires_at * 1000 > Date.now();
    if (isTokenStillValid) {
        clearTimeout(loginTimeout);
        const user = await oidcContext?.signinSilent();
        if (user) {
            token.next(user.access_token);
            const tokenExpirationDate = (JSON.parse(atob(user.access_token.split('.')[1]))).exp * 1000;
            tokenExpiryDate.next(tokenExpirationDate);
            initializeUser();
            setTokenRefresh(oidcContext);
        }
    }
    else if (!oidcContext?.isLoading && !oidcContext?.activeNavigator && !document.hidden) {
        sessionStorage.setItem('pathname', window.location.pathname);
        oidcContext?.signinRedirect();
    }
}

const initializeUser = () => {
    if (userLoggedIn.value == false) {
        UserStore.initUser();
        userLoggedIn.next(true);
    }
}

const UserStore = {
    user,
    token,
    userLoggedIn,
    loginUser: async (oidcContext: AuthContextProps) => {
        if (token.value != undefined && token.value.length > 0) {
            initializeUser();
        }
        else {
            clearTimeout(loginTimeout);
            loginTimeout = setTimeout(() => checkAuth(oidcContext), 1000);
        }
    },
    setUser: (resp: IUser) => {
        user.next(resp);
        ExecutorStore.initExecutorList();
    },
    initUser: function () {
        API.get<IUser>(getUserUrl).then((resp) => {
            this.setUser(resp);
        }).catch((error) => {
            throw error;
        });
    }
}

export {
    UserStore,
    checkAuth
}