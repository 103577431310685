import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImageViewer from '../../components/ImageViewer/ImageViewer';
import { useParams, useHistory, useLocation } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton, TablePagination, TextareaAutosize, Grid, Typography, TableContainer, Paper, Tooltip } from '@mui/material';
import { TextFieldComponent, NumberInputFieldComponent, CheckboxComponent, ButtonComponent, HeadingComponent, SimpleCheckbox, ModalComponent, TableHeadCells, TableHeadingContent, LinkComponent } from '../../components/Reusable/';
import * as Mapper from '../../helper/Mappers';
import { API } from '../../helper/ApiHelper';
import { CompressedAirGenerationModelFactory } from './CompressedAirGenerationModel';
import AirProfileChart from './AirProfileChart/AirProfileChart';
import * as Fmt from '../../helper/Formatters';
import * as ImageBlobConstants from '../ImageViewer/Constants';
import { FilterModel } from "../Reusable/TableHeadingContent/GlobalFilterModel";
import { OpenGlobalSnackbar, ScrollerToTag } from '../../helper/GlobalVariables';
import * as InputRestrictions from '../../helper/InputRestrictions';
import { checkAndValidateModel } from "../../helper/Validator";
import { useGlobalState } from 'state-pool';
import { CompressorModelFactory, CompressorCalcsModelFactory, CompRoomCalcsModelFactory } from './EditIndividualCompressorInformation/CompressorModel'
import ImportFullDialog from '../ImportDryerCompressorFull/ImportFullDialog';
import { DirtyStore } from '../../store';
import * as Consts from '../../helper/ConstantRepository';
import './CompressedAirGeneration.css';
import { getLocale } from '../../helper/CountryLanguages';

export default function CompressedAirGeneration(props) {
    const { denyEdit } = props;
    const cagUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "compressed-air-generation";
    const { t } = useTranslation();
    let { id } = useParams();
    const { country } = getLocale();
    let history = useHistory();
    const location = useLocation();
    const [isCagExplicitlySelected, setIsCagExplicitlySelected] = useState(true);
    const [compressorListCurrentPage, setCompressorListCurrentPage] = useGlobalState("CAGPage" + id, { default: 0 });
    const [compressorListRowsPerPage, setCompressorListRowsPerPage] = useState(10);
    const [compressorRoomListCurrentPage, setCompressorRoomListCurrentPage] = useState(0);
    const [compressorRoomListRowsPerPage, setCompressorRoomListRowsPerPage] = useState(10);
    const [compressedAirGenerationModel, setCompressedAirGenerationModel] = useState(CompressedAirGenerationModelFactory(t))
    const [compressorToDeleteList, setCompressorToDeleteList] = useState([])
    const [compressorList, setCompressorList] = useState([])
    const [filterList, setFilterList] = useState([])
    const [cagCalculationsResult, setCagCalculationsResult] = useState({});
    const [compressorCalcs, setCompressorCalcs] = useState([]);
    const [compRoomCalcs, setCompRoomCalcs] = useState([]);
    const [importOpen, setImportOpen] = useState(false);
    const [modalModel, setModalModel] = useState({
        open: false,
        message: ""
    });

    const compressorModel = CompressorModelFactory(t);
    const compressorCalcsModel = CompressorCalcsModelFactory(t);
    const compRoomCalcsModel = CompRoomCalcsModelFactory(t);

    useEffect(() => {
        setIsCagExplicitlySelected(props?.menuData?.isAnalysisOfTheCompressedAirGenerationSelected);
    }, [props.menuData]);

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(compressedAirGenerationModel, CompressedAirGenerationModelFactory(t), setCompressedAirGenerationModel);
    }, [t]);

    const handleChangeCompressorListCurrentPage = (event, newPage) => {
        setCompressorListCurrentPage(newPage);
    };

    const handleChangeCompressorListRowsPerPage = (event) => {
        setCompressorListRowsPerPage(parseInt(event.target.value, 10));
        setCompressorListCurrentPage(0);
    };

    const handleChangeCompressorRoomListCurrentPage = (event, newPage) => {
        setCompressorRoomListCurrentPage(newPage);
    };

    const handleChangeCompressorRoomListRowsPerPage = (event) => {
        setCompressorRoomListRowsPerPage(parseInt(event.target.value, 10));
        setCompressorRoomListCurrentPage(0);
    };

    useEffect(() => {
        if (id) {
            loadExistingCag(true);
        }
    }, [id])

    const loadExistingCag = (initialValue) => {
        API.get(cagUrl + '/' + id).then(resp => {
            setCompressorToDeleteList([])
            let mappedModel = Mapper.mapDataToModelValues(resp.compressedAirGeneration, CompressedAirGenerationModelFactory(t));
            setCagCalculationsResult({ ...resp.cagCalculationResult });
            setCompressorCalcs(resp.cagCalculationResult.compressorCalcs.map((item, no) => ({ ...item, no })));
            setCompRoomCalcs(resp.cagCalculationResult.compRoomCalcs);
            setCompressorList([...resp.compressedAirGeneration.compressorList.sort((a, b) => b.measurementId < a.measurementId)]);
            if (location.hash.includes('validate')) {
                let [, validatedModel] = checkAndValidateModel(mappedModel);
                mappedModel = validatedModel;
            }
            setCompressedAirGenerationModel({ ...mappedModel });

            setFilterList([...resp.compressedAirGeneration.compressorList]);
            DirtyStore.setIsDirty(false);
            if (initialValue) ScrollerToTag();
        })
    }

    const updateModelHandler = (property, value) => {
        let updatedModel = Mapper.updateModel(property, value, compressedAirGenerationModel)
        setCompressedAirGenerationModel({ ...updatedModel });
        DirtyStore.setIsDirty(postForm);
    }

    const handleCheck = (index, value) => {
        compressorToDeleteList[index] = value;
        setCompressorToDeleteList([...compressorToDeleteList])
    }

    const isCheckedForDeletion = (index) => {
        return (
            (compressorToDeleteList[index] != undefined &&
                compressorToDeleteList[index] == true)
        );
    }

    const validateFormModel = () => {
        let [isValid, validatedModel] = checkAndValidateModel(compressedAirGenerationModel);
        setCompressedAirGenerationModel({ ...validatedModel });
        return isValid;
    }

    const postForm = () => {
        let isValid = validateFormModel();
        if (isValid === true) {
            let postModel = Mapper.extractValuesFromModel(compressedAirGenerationModel);
            let saveData = {
                ProjectId: id,
                CompressedAirGeneration: postModel
            }
            saveData.CompressedAirGeneration.compressorList = [];
            saveData.CompressedAirGeneration.compressorRoomList = [];
            API.put(cagUrl + '/' + id, saveData).then(() => {
                OpenGlobalSnackbar("success", t('SaveSuccessful'));
                loadExistingCag();
            })
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
        return isValid;
    };

    const handleDeleteSelectedCompressors = () => {
        if (compressorList) {
            var compressorsToDelete = compressorList.filter((c, i) =>
                compressorToDeleteList.some((d, ind) => d == true && i == ind)
            );
            if (compressorsToDelete && compressorsToDelete.length > 0) {
                var compressorsIds = compressorsToDelete.map(c => c.id);
                if (compressorsIds) {
                    let saveData = {
                        ProjectId: id,
                        CompressorIds: compressorsIds
                    }
                    API.delete(`${cagUrl}/${id}/compressor/delete`, { data: saveData }).then(() => {
                        OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
                        setCompressorToDeleteList([]);
                        loadExistingCag();
                    })
                }
            }
        }
    };

    const getCompressorLink = compressorId => `/project/${id}/CAG/AddEditCompressor/${compressorId}#Tabs`;

    const handleAddEditCompressor = (compressorId) => history.push(getCompressorLink(compressorId));

    const handleCancelClick = () => {
        history.push(`/projectsandrequests`);
    }

    const clickDelete = () => {
        if (compressorToDeleteList == undefined || compressorToDeleteList.length == 0)
            OpenGlobalSnackbar("danger", t('NoEntriesCheckedError'));
        else
            clickDeleteOpenModal();
    }

    const clickDeleteOpenModal = () => {
        modalModel.open = true;
        modalModel.okText = t('Yes');
        modalModel.cancelText = t('No');
        modalModel.message = t('DeleteSelectedRowsQuestion');
        modalModel.clickOk = handleDeleteSelectedCompressors;
        setModalModel({ ...modalModel });
    }

    const filterFunction = (tempdata) => {
        setCompressorList(tempdata);
        setCompressorListCurrentPage(0);
    }

    return (
        <div className="compressed-air-generation table-layout-fix">
            <ModalComponent model={modalModel} />
            <ImportFullDialog
                open={importOpen}
                setOpen={setImportOpen}
                viewList={[Consts.ProjectImportCompressor]}
                reloadData={loadExistingCag}
            />
            <Grid container spacing={5} direction="column">
                <Grid item id="#IndividualCompressorDetails">
                    <HeadingComponent value={t('IndividualCompressorDetails')} size="h6" />
                </Grid>
                <Grid item>
                    <TableHeadingContent
                        clickImport={() => setImportOpen(true)}
                        clickDelete={clickDelete}
                        clickAdd={() => handleAddEditCompressor(0)}
                        defaultData={compressorList}
                        filterData={filterList}
                        onFilterFunction={filterFunction}
                        filterModel={FilterModel.CompressedAirGeneration}
                        denyEdit={denyEdit}
                        tableId={"CAG"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow >
                                    {!denyEdit && <TableCell></TableCell>}
                                    <TableHeadCells
                                        model={compressorModel}
                                        propertyList={["no", "measurementId", "compressorRoomNo", "manufacturer", "modelSerialNumber", "year", "compressorType", "controlType"]}
                                        tableName="IndividualCompressorDetails"
                                        data={compressorList}
                                        setData={setCompressorList}
                                    />
                                    <TableCell></TableCell>
                                </TableRow >
                            </TableHead >
                            {compressorList && compressorList.length > 0 ?
                                <TableBody>
                                    {compressorList.slice(compressorListCurrentPage * compressorListRowsPerPage, compressorListCurrentPage * compressorListRowsPerPage + compressorListRowsPerPage).map((compressor, index) =>
                                        <TableRow key={index + 1}>
                                            {!denyEdit &&
                                                <TableCell>
                                                    <SimpleCheckbox
                                                        id={"CAG-checkbox" + index}
                                                        key={Math.random()}
                                                        defaultChecked={isCheckedForDeletion(compressorListCurrentPage * compressorListRowsPerPage + index)}
                                                        color="default"
                                                        onChange={e => handleCheck(compressorListCurrentPage * compressorListRowsPerPage + index, e.target.checked)}>
                                                    </SimpleCheckbox>
                                                </TableCell>
                                            }
                                            <TableCell id={"td_CAG-compressorCalcs" + index}>{index + 1}</TableCell>
                                            <TableCell id={"td_CAG-measurementId" + index}>
                                                <LinkComponent
                                                    link={getCompressorLink(compressor.id)}
                                                    innerComponent={compressor.measurementId}
                                                />
                                            </TableCell>
                                            <TableCell id={"td_CAG-compressorRoomNo" + index}>{compressor.compressorRoomNo}</TableCell>
                                            <TableCell id={"td_CAG-manufacturer" + index}>{compressor.manufacturer}</TableCell>
                                            <TableCell id={"td_CAG-modelSerialNumber" + index}>{compressor.modelSerialNumber}</TableCell>
                                            <TableCell id={"td_CAG-year" + index}>{compressor.year}</TableCell>
                                            <TableCell id={"td_CAG-compressorType" + index}>{t(compressor.compressorType?.value)}</TableCell>
                                            <TableCell id={"td_CAG-controlType" + index}>{t(compressor.controlType?.value)}</TableCell>
                                            <TableCell >
                                                <IconButton
                                                    id={"btn_CAG-edit" + index}
                                                    href={getCompressorLink(compressor.id)}
                                                    onClick={() => handleAddEditCompressor(compressor.id)}
                                                    size="large"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                : <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={10}>
                                            <div>{t('NoData')}</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>}
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={compressorList && compressorList.length > 0 ? compressorList.length : 0}
                        rowsPerPage={compressorListRowsPerPage}
                        page={compressorListCurrentPage}
                        onPageChange={handleChangeCompressorListCurrentPage}
                        onRowsPerPageChange={handleChangeCompressorListRowsPerPage}
                    />
                </Grid>
                <Grid item id="#CompressorCapacityInformation">
                    <HeadingComponent value={t('CompressorCapacity')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableHeadCells
                                        propertyList={["no", "modelSerialNumber", "nominalPower", "freeAirDelivery", "nominalPressure", "setpointPressure", "lastMaintenance", "heatRecovery"]}
                                        model={compressorModel}
                                        tableName="CompressorCapacity"
                                        data={compressorList}
                                        setData={setCompressorList}
                                    />
                                </TableRow>
                            </TableHead>
                            {compressorList && compressorList.length > 0 ?
                                <TableBody>
                                    {compressorList.slice(compressorListCurrentPage * compressorListRowsPerPage, compressorListCurrentPage * compressorListRowsPerPage + compressorListRowsPerPage).map((compressor, index) =>
                                        <TableRow key={index + 1}>
                                            <TableCell id={"td_CAG-compressorCalcs" + index}>{index + 1}</TableCell>
                                            <TableCell id={"td_CAG-modelSerialNumber" + index}>{compressor.modelSerialNumber}</TableCell>
                                            <TableCell id={"td_CAG-nominalPower" + index}>{Fmt.round(compressor.nominalPower, country, 0)}</TableCell>
                                            <TableCell id={"td_CAG-freeAirDelivery" + index}>{Fmt.round(compressor.freeAirDelivery, country, 1)}</TableCell>
                                            <TableCell id={"td_CAG-nominalPressure" + index}>{Fmt.round(compressor.nominalPressure, country, 1)}</TableCell>
                                            <TableCell id={"td_CAG-setpointPressure" + index}>{Fmt.round(compressor.setpointPressure, country, 1)}</TableCell>
                                            <TableCell id={"td_CAG-lastMaintenance" + index}>{compressor.lastMaintenance ? new Intl.DateTimeFormat("lt-LT", { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(compressor.lastMaintenance)) : " "}</TableCell>
                                            <TableCell>
                                                <CheckboxComponent
                                                    disabled
                                                    id={"CAG-heatRecovery" + index}
                                                    model={{ value: compressor.heatRecovery, isValid: true }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                : <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={9}>
                                            <div>{t('NoData')}</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={compressorList && compressorList.length > 0 ? compressorList.length : 0}
                        rowsPerPage={compressorListRowsPerPage}
                        page={compressorListCurrentPage}
                        onPageChange={handleChangeCompressorListCurrentPage}
                        onRowsPerPageChange={handleChangeCompressorListRowsPerPage}
                    />
                </Grid>

                {isCagExplicitlySelected &&
                    <React.Fragment>
                        <Grid item>
                            <HeadingComponent value={t('CompressorExpectedOutput')} size="h6" />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="spanning table">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadCells
                                                model={compressorCalcsModel}
                                                propertyList={["no", "rEffectivePower", "rfadOperatingConditions"]}
                                                tableName="CompressorExpectedOutput"
                                                data={compressorCalcs}
                                                setData={setCompressorCalcs}
                                            />
                                        </TableRow>
                                    </TableHead>
                                    {!!compressorCalcs && compressorList.length > 0 ?
                                        <TableBody>
                                            {compressorCalcs.slice(compressorListCurrentPage * compressorListRowsPerPage, compressorListCurrentPage * compressorListRowsPerPage + compressorListRowsPerPage).map((compressor, index) =>
                                                <TableRow key={index + 1}>
                                                    <TableCell id={"td_CAG-index" + index + 1} width="10%">{index + 1}</TableCell>
                                                    <TableCell id={"td_CAG-rEffectivePower" + index}>{Fmt.round(compressor.rEffectivePower, country, 0)}</TableCell>
                                                    <TableCell id={"td_CAG-rfadOperatingConditions" + index}>{Fmt.round(compressor.rfadOperatingConditions, country, 1)}</TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow key={-1}>
                                                <TableCell id={"td_CAG-measurementSystem"} className="font-bold">{t('Total')}</TableCell>
                                                <TableCell id={"td_CAG-rEffectivePower"} className="font-bold">{Fmt.round(cagCalculationsResult.compressorCalcs.reduce((sum, x) => sum + x.rEffectivePower, 0), country, 0)}</TableCell>
                                                <TableCell id={"td_CAG-rEffectivePower"} className="font-bold">{Fmt.round(cagCalculationsResult.cfadOperatingConditionsTOTAL, country, 2)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        : <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <div>{t('NoData')}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                component="div"
                                count={compressorList && compressorList.length > 0 ? compressorList.length : 0}
                                rowsPerPage={compressorListRowsPerPage}
                                page={compressorListCurrentPage}
                                onPageChange={handleChangeCompressorListCurrentPage}
                                onRowsPerPageChange={handleChangeCompressorListRowsPerPage}
                            />
                        </Grid>
                        <Grid item>
                            <HeadingComponent value={t('ExpectedOutputByCompressorRoom')} size="h6" />
                        </Grid>
                        {
                            Object.keys(cagCalculationsResult).length > 0 &&
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="spanning table">
                                        <TableHead>
                                            <TableRow>
                                                <TableHeadCells
                                                    model={compRoomCalcsModel}
                                                    propertyList={["no", "cEffectivePowerROOM", "cfadOperatingConditionsROOM"]}
                                                    styleList={[{ width: "20%" }, {}, {}]}
                                                    tableName="ExpectedOutputByCompressorRoom"
                                                    data={compRoomCalcs}
                                                    setData={setCompRoomCalcs}
                                                />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {compRoomCalcs.slice(compressorRoomListCurrentPage * compressorRoomListRowsPerPage, compressorRoomListCurrentPage * compressorRoomListRowsPerPage + compressorRoomListRowsPerPage).map((compressorRoom, index) =>
                                                <TableRow key={compressorRoom.no}>
                                                    <TableCell id={"td_CAG-no" + index}>{compressorRoom.no}</TableCell>
                                                    <TableCell id={"td_CAG-cEffectivePowerROOM" + index}>{Fmt.round(compressorRoom.cEffectivePowerROOM, country, 0)}</TableCell>
                                                    <TableCell id={"td_CAG-cfadOperatingConditionsROOM" + index}>{Fmt.round(compressorRoom.cfadOperatingConditionsROOM, country, 1)}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    component="div"
                                    count={cagCalculationsResult.compRoomCalcs && cagCalculationsResult.compRoomCalcs.length > 0 ? cagCalculationsResult.compRoomCalcs.length : 0}
                                    rowsPerPage={compressorRoomListRowsPerPage}
                                    page={compressorRoomListCurrentPage}
                                    onPageChange={handleChangeCompressorRoomListCurrentPage}
                                    onRowsPerPageChange={handleChangeCompressorRoomListRowsPerPage}
                                />
                            </Grid>
                        }
                    </React.Fragment>
                }

                <Grid item id="#MeasurementOfTheCompressedAirGeneration">
                    <HeadingComponent value={t('MeasurementOfTheCompressedAirGeneration')} size="h6" />
                </Grid>

                <Grid item>
                    <CheckboxComponent disabled={denyEdit} model={compressedAirGenerationModel.isElectricityAndAirConsumptionMeasured} onChange={e => {
                        updateModelHandler("isElectricityAndAirConsumptionMeasured", e.target.checked);
                    }} />

                </Grid>

                {/*----------------------------------------------------------------------------------------------------------------------------------------------*/}
                {compressedAirGenerationModel != null && compressedAirGenerationModel.isElectricityAndAirConsumptionMeasured != null && compressedAirGenerationModel.isElectricityAndAirConsumptionMeasured.value &&

                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="spanning table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{t('TimePeriod') + Fmt.getUnitInBracket("hours", t)}</TableCell>
                                        <TableCell>{t('Measurement')}</TableCell>
                                        <TableCell>{t('ResultsPerYear')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={0}>
                                        <TableCell>{t('ElectricityConsumption') + Fmt.getUnitInBracket("powerPerHour", t)}</TableCell>
                                        <TableCell>
                                            <NumberInputFieldComponent disabled={denyEdit} id="electricityConsumptionTimePeriod" t={t} model={compressedAirGenerationModel.electricityConsumptionTimePeriod} onChange={e => {
                                                updateModelHandler("electricityConsumptionTimePeriod", e.target.value)
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <NumberInputFieldComponent disabled={denyEdit} id="electricityConsumptionValue" t={t} model={compressedAirGenerationModel.electricityConsumptionValue} onChange={e => {
                                                updateModelHandler("electricityConsumptionValue", InputRestrictions.Decimal(e.target.value, 2))
                                            }} />
                                        </TableCell>
                                        <TableCell id="mYearlyConsumptionElectricityTOTAL">
                                            {Fmt.round(cagCalculationsResult.mYearlyConsumptionElectricityTOTAL, country, 0)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={1}>
                                        <TableCell>{t('AirConsumption') + Fmt.getUnitInBracket("cubicMeter", t)}</TableCell>
                                        <TableCell>
                                            <NumberInputFieldComponent disabled={denyEdit} id="airConsumptionTimePeriod" t={t} model={compressedAirGenerationModel.airConsumptionTimePeriod} onChange={e => {
                                                updateModelHandler("airConsumptionTimePeriod", e.target.value)
                                            }} />
                                        </TableCell>
                                        <TableCell>
                                            <NumberInputFieldComponent disabled={denyEdit} id="airConsumptionValue" t={t} model={compressedAirGenerationModel.airConsumptionValue} onChange={e => {
                                                updateModelHandler("airConsumptionValue", InputRestrictions.Decimal(e.target.value, 2))
                                            }} />
                                        </TableCell>
                                        <TableCell id="CAG_mYearlyConsumptionAirConsumptionTOTAL">
                                            {Fmt.round(cagCalculationsResult.mYearlyConsumptionAirConsumptionTOTAL, country, 0)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                }
                {/*----------------------------------------------------------------------------------------------------------------------------------------------*/}
                {/*----------------------------------------------------------------------------------------------------------------------------------------------*/}

                {
                    !!compressorList && !!cagCalculationsResult && isCagExplicitlySelected &&
                    <React.Fragment>
                        <Grid item id="#WorkloadCompressorsInformation">
                            <HeadingComponent value={t('Workload')} size="h6" />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="spanning table">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadCells
                                                propertyList={["no", "modelSerialNumber", "mondayToFridayWorkload", "saturdayWorkload", "sundayWorkload"]}
                                                model={compressorModel}
                                                tableName="Workload"
                                                data={compressorList}
                                                setData={setCompressorList}
                                            />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {compressorList.slice(compressorListCurrentPage * compressorListRowsPerPage, compressorListCurrentPage * compressorListRowsPerPage + compressorListRowsPerPage).map((compressor, index) =>
                                            <TableRow key={index + 1}>
                                                <TableCell id={"td_CAG-index" + index}>{index + 1}</TableCell>
                                                <TableCell id={"td_CAG-modelSerialNumber" + index}>{compressor.modelSerialNumber}</TableCell>
                                                <TableCell id={"td_CAG-mondayToFridayWorkload" + index}>{Fmt.round(compressor.mondayToFridayWorkload, country, 2)}</TableCell>
                                                <TableCell id={"td_CAG-saturdayWorkload" + index}>{Fmt.round(compressor.saturdayWorkload, country, 2)}</TableCell>
                                                <TableCell id={"td_CAG-sundayWorkload" + index}>{Fmt.round(compressor.sundayWorkload, country, 2)}</TableCell>
                                            </TableRow>

                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                component="div"
                                count={compressorList && compressorList.length > 0 ? compressorList.length : 0}
                                rowsPerPage={compressorListRowsPerPage}
                                page={compressorListCurrentPage}
                                onPageChange={handleChangeCompressorListCurrentPage}
                                onRowsPerPageChange={handleChangeCompressorListRowsPerPage}
                            />
                        </Grid>
                        <Grid item>
                            <HeadingComponent value={t('CompressorRoomAverageWorkloadPerDay')} size="h6" />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="spanning table">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadCells
                                                model={compRoomCalcsModel}
                                                propertyList={["no", "rFlowMondayFridayWorkloadROOM", "rFlowSaturdayWorkloadROOM", "rFlowSundayWorkloadROOM"]}
                                                styleList={[{ width: "20%" }, {}, {}, {}]}
                                                tableName="CompressorRoomAverageWorkloadPerDay"
                                                data={compRoomCalcs}
                                                setData={setCompRoomCalcs}
                                            />
                                        </TableRow>
                                    </TableHead>
                                    {compRoomCalcs && compRoomCalcs.length > 0 ?
                                        <TableBody>
                                            {compRoomCalcs.slice(compressorRoomListCurrentPage * compressorRoomListRowsPerPage, compressorRoomListCurrentPage * compressorRoomListRowsPerPage + compressorRoomListRowsPerPage).map((compressorRoom, index) =>
                                                <TableRow key={index}>
                                                    <TableCell id={"td_CAG-no" + index} width="20%">{compressorRoom.no}</TableCell>
                                                    <TableCell id={"td_CAG-rFlowMondayFridayWorkloadROOM" + index}>{Fmt.round(compressorRoom.rFlowMondayFridayWorkloadROOM, country, 0)}</TableCell>
                                                    <TableCell id={"td_CAG-rFlowSaturdayWorkloadROOM" + index}>{Fmt.round(compressorRoom.rFlowSaturdayWorkloadROOM, country, 0)}</TableCell>
                                                    <TableCell id={"td_CAG-rFlowSundayWorkloadROOM" + index}>{Fmt.round(compressorRoom.rFlowSundayWorkloadROOM, country, 0)}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        : <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <div>{t('NoData')}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>}
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                component="div"
                                count={cagCalculationsResult.compRoomCalcs && cagCalculationsResult.compRoomCalcs.length > 0 ? cagCalculationsResult.compRoomCalcs.length : 0}
                                rowsPerPage={compressorRoomListRowsPerPage}
                                page={compressorRoomListCurrentPage}
                                onPageChange={handleChangeCompressorRoomListCurrentPage}
                                onRowsPerPageChange={handleChangeCompressorRoomListRowsPerPage}
                            />
                        </Grid>
                    </React.Fragment>
                }

                <Grid item id="#StaticConsumptionHeader">
                    <HeadingComponent value={t('StaticConsumptionHeader')} size="h6" />
                </Grid>
                <Grid item xs={12}> {Object.keys(cagCalculationsResult).length > 0 &&
                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t('LevelProductionTime') + Fmt.getPercentage()}</TableCell>
                                    <TableCell>{t('LevelTotal') + Fmt.getPercentage()}</TableCell>
                                    <TableCell>{t('FlowTotal') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={0}>
                                    <TableCell>{t('StaticConsumption')}</TableCell>
                                    <TableCell>
                                        <NumberInputFieldComponent
                                            disabled={denyEdit}
                                            t={t}
                                            model={compressedAirGenerationModel.staticConsumption}
                                            onChange={e => updateModelHandler("staticConsumption", InputRestrictions.Decimal(e.target.value))}
                                        />
                                    </TableCell>
                                    <TableCell>{Fmt.round(cagCalculationsResult.rStaticConsumptionPercentageTOTAL, country, 1)}</TableCell>
                                    <TableCell>{Fmt.round(cagCalculationsResult.rStaticConsumptionAirFlowTOTAL, country, 1)}</TableCell>
                                </TableRow>
                                <TableRow key={1}>
                                    <TableCell>{t('ExpectedLeakages')}</TableCell>
                                    <TableCell>
                                        <NumberInputFieldComponent
                                            disabled={denyEdit}
                                            t={t}
                                            model={compressedAirGenerationModel.expectedLeakages}
                                            onChange={e => updateModelHandler("expectedLeakages", InputRestrictions.Decimal(e.target.value))}
                                        />
                                    </TableCell>
                                    <TableCell>{Fmt.round(cagCalculationsResult.rLeakagePercentageTOTAL, country, 1)}</TableCell>
                                    <TableCell>{Fmt.round(cagCalculationsResult.rLeakagesAirFlowTOTAL, country, 1)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Typography>
                            * {t("EstimationOfStaticConsumptionLabel")}
                        </Typography>
                    </TableContainer>
                }
                </Grid>
                <Grid item id="#CompressorUnloadTime">
                    <HeadingComponent value={t('CompressorUnloadTime')} size="h6" />
                </Grid>
                <Grid item>
                    <TextFieldComponent disabled={denyEdit} t={t} model={compressedAirGenerationModel.unloadTime} onChange={e => {
                        updateModelHandler("unloadTime", e.target.value)
                    }} />
                </Grid>
                <Grid item>
                    <NumberInputFieldComponent disabled={denyEdit} t={t} model={compressedAirGenerationModel.unloadCyclesPerHour} onChange={e => {
                        updateModelHandler("unloadCyclesPerHour", InputRestrictions.Decimal(e.target.value))
                    }} />
                </Grid>
                <Grid item>
                    <TextFieldComponent
                        disabled
                        t={t}
                        model={{
                            value: Fmt.round(cagCalculationsResult.unloadTimeCompressorsPercentage, country, 2),
                            isValid: true,
                            label: t("UnloadTimeRatio") + Fmt.getPercentage(),
                            idkey: "CAG-unloadTimePerHour"
                        }}
                    />
                </Grid>
                {isCagExplicitlySelected &&
                    <React.Fragment>
                        <Grid item id="#FlowRateInformation">
                            <HeadingComponent value={t('AverageAirFlowAndReserve')} size="h6" />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="spanning table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>{t('MondayFriday')}</TableCell>
                                            <TableCell>{t('Saturday')}</TableCell>
                                            <TableCell>{t('Sunday')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={-1}>
                                            <TableCell>{t('FADCorrected') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</TableCell>
                                            <TableCell id="td_CAG-rFlowMondayFridayTOTALWorkload">{Fmt.round(cagCalculationsResult.cfadOperatingConditionsTOTAL, country, 1)}</TableCell>
                                            <TableCell id="td_CAG-rFlowSaturdayTOTALWorkload">{Fmt.round(cagCalculationsResult.cfadOperatingConditionsTOTAL, country, 1)}</TableCell>
                                            <TableCell id="td_CAG-rFlowSundayTOTALWorkload">{Fmt.round(cagCalculationsResult.cfadOperatingConditionsTOTAL, country, 1)} </TableCell>
                                        </TableRow>
                                        <TableRow key={0}>
                                            <TableCell>{t('AverageAirFlow') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</TableCell>
                                            <TableCell id="td_CAG-rFlowMondayFridayTOTAL">{Fmt.round(cagCalculationsResult.rFlowMondayFridayTOTAL, country, 1)}</TableCell>
                                            <TableCell id="td_CAG-rFlowSaturdayTOTAL">{Fmt.round(cagCalculationsResult.rFlowSaturdayTOTAL, country, 1)}</TableCell>
                                            <TableCell id="td_CAG-rFlowSundayTOTAL">{Fmt.round(cagCalculationsResult.rFlowSundayTOTAL, country, 1)}</TableCell>
                                        </TableRow>
                                        <TableRow key={1}>
                                            <TableCell>{t('Reserve') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</TableCell>
                                            <TableCell
                                                id="td_CAG-rFlowMondayFridayTOTALReserve"
                                                className={cagCalculationsResult?.compressorCalcs?.some(x => x.rFlowMondayFriday > cagCalculationsResult.rFlowMondayFridayTOTALReserve) && "mark-red"}
                                            >
                                                {Fmt.round(cagCalculationsResult.rFlowMondayFridayTOTALReserve, country, 1)}
                                            </TableCell>
                                            <TableCell
                                                id="td_CAG-rFlowSaturdayTOTALReserve"
                                                className={cagCalculationsResult?.compressorCalcs?.some(x => x.rFlowSaturday > cagCalculationsResult.rFlowSaturdayTOTALReserve) && "mark-red"}
                                            >
                                                {Fmt.round(cagCalculationsResult.rFlowSaturdayTOTALReserve, country, 1)}
                                            </TableCell>
                                            <TableCell
                                                id="td_CAG-rFlowSundayTOTALReserve"
                                                className={cagCalculationsResult?.compressorCalcs?.some(x => x.rFlowSunday > cagCalculationsResult.rFlowSundayTOTALReserve) && "mark-red"}
                                            >
                                                {Fmt.round(cagCalculationsResult.rFlowSundayTOTALReserve, country, 1)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <HeadingComponent value={t('CompressorAverageAirFlowPerDay')} size="h6" />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="spanning table">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadCells
                                                model={compressorCalcsModel}
                                                propertyList={["no", "rFlowMondayFriday", "rFlowSaturday", "rFlowSunday"]}
                                                styleList={[{ width: "20%" }, {}, {}, {}]}
                                                tableName="CompressorAverageAirFlowPerDay"
                                                data={compressorCalcs}
                                                setData={setCompressorCalcs}
                                            />
                                        </TableRow>
                                    </TableHead>
                                    {cagCalculationsResult.compressorCalcs && compressorList.length > 0 ?
                                        <TableBody>
                                            {cagCalculationsResult.compressorCalcs.slice(compressorListCurrentPage * compressorListRowsPerPage, compressorListCurrentPage * compressorListRowsPerPage + compressorListRowsPerPage).map((compressor, index) =>
                                                <TableRow key={index + 1}>
                                                    <TableCell id={"td_CAG-no" + index}>{index + 1}</TableCell>
                                                    <TableCell
                                                        id={"td_CAG-rFlowMondayFriday" + index}
                                                        className={compressor.rFlowMondayFriday > cagCalculationsResult.rFlowMondayFridayTOTALReserve && "mark-red"}
                                                    >
                                                        {Fmt.round(compressor.rFlowMondayFriday, country, 1)}
                                                    </TableCell>
                                                    <TableCell
                                                        id={"td_CAG-rFlowSaturday" + index}
                                                        className={compressor.rFlowSaturday > cagCalculationsResult.rFlowSaturdayTOTALReserve && "mark-red"}
                                                    >
                                                        {Fmt.round(compressor.rFlowSaturday, country, 1)}
                                                    </TableCell>
                                                    <TableCell
                                                        id={"td_CAG-rFlowSunday" + index}
                                                        className={compressor.rFlowSunday > cagCalculationsResult.rFlowSundayTOTALReserve && "mark-red"}
                                                    >
                                                        {Fmt.round(compressor.rFlowSunday, country, 1)}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        : <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <div>{t('NoData')}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>}
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                component="div"
                                count={compressorList && compressorList.length > 0 ? compressorList.length : 0}
                                rowsPerPage={compressorListRowsPerPage}
                                page={compressorListCurrentPage}
                                onPageChange={handleChangeCompressorListCurrentPage}
                                onRowsPerPageChange={handleChangeCompressorListRowsPerPage}
                            />
                        </Grid>
                        <Grid item>
                            <HeadingComponent value={t('CompressorRoomAverageAirFlowPerDay')} size="h6" />
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="spanning table">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadCells
                                                model={compRoomCalcsModel}
                                                propertyList={["no", "rFlowMondayFridayROOM", "rFlowSaturdayROOM", "rFlowSundayROOM"]}
                                                styleList={[{ width: "20%" }, {}, {}, {}]}
                                                tableName="CompressorRoomAverageAirFlowPerDay"
                                                data={compRoomCalcs}
                                                setData={setCompRoomCalcs}
                                            />
                                        </TableRow>
                                    </TableHead>
                                    {compRoomCalcs && compRoomCalcs.length > 0 ?
                                        <TableBody>
                                            {compRoomCalcs.slice(compressorRoomListCurrentPage * compressorRoomListRowsPerPage, compressorRoomListCurrentPage * compressorRoomListRowsPerPage + compressorRoomListRowsPerPage).map((compressorRoom, index) =>
                                                <TableRow key={compressorRoom.no}>
                                                    <TableCell id={"td_CAG-no" + index}>{compressorRoom.no}</TableCell>
                                                    <TableCell id={"td_CAG-rFlowMondayFridayROOM" + index}>{Fmt.round(compressorRoom.rFlowMondayFridayROOM, country, 1)}</TableCell>
                                                    <TableCell id={"td_CAG-rFlowSaturdayROOM" + index}>{Fmt.round(compressorRoom.rFlowSaturdayROOM, country, 1)}</TableCell>
                                                    <TableCell id={"td_CAG-rFlowSundayROOM" + index}>{Fmt.round(compressorRoom.rFlowSundayROOM, country, 1)}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        : <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <div>{t('NoData')}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>}
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                component="div"
                                count={cagCalculationsResult.compRoomCalcs && cagCalculationsResult.compRoomCalcs.length > 0 ? cagCalculationsResult.compRoomCalcs.length : 0}
                                rowsPerPage={compressorRoomListRowsPerPage}
                                page={compressorRoomListCurrentPage}
                                onPageChange={handleChangeCompressorRoomListCurrentPage}
                                onRowsPerPageChange={handleChangeCompressorRoomListRowsPerPage}
                            />
                        </Grid>


                        <Grid item id="#YearlyConsumptionInformation">
                            <HeadingComponent value={t('YearlyConsumption')} size="h6" />
                        </Grid>
                        {
                            Object.keys(cagCalculationsResult).length > 0 &&
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="spanning table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="20%"></TableCell>
                                                <TableCell>{t('Calculated')}</TableCell>
                                                <TableCell>{t('Measured')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={0}>
                                                <TableCell>{t('ElectricityConsumtion') + Fmt.getUnitInBracket("powerPerHour", t)}</TableCell>
                                                <TableCell id="td_CAG-cYearlyConsumptionElectricityTOTAL">{Fmt.round(cagCalculationsResult.cYearlyConsumptionElectricityTOTAL, country, 0)}</TableCell>
                                                <TableCell id="td_CAG-mYearlyConsumptionElectricityTOTAL">{Fmt.round(cagCalculationsResult.mYearlyConsumptionElectricityTOTAL, country, 0)}</TableCell>
                                            </TableRow>
                                            <TableRow key={1}>
                                                <TableCell>{t('AirConsumtion') + Fmt.getUnitInBracket("cubicMeterOfGas", t)}</TableCell>
                                                <TableCell id="td_CAG-cYearlyConsumptionAirConsumptionTOTAL">{Fmt.round(cagCalculationsResult.cYearlyConsumptionAirConsumptionTOTAL, country, 0)}</TableCell>
                                                <TableCell id="td_CAG-mYearlyConsumptionAirConsumptionTOTAL">{Fmt.round(cagCalculationsResult.mYearlyConsumptionAirConsumptionTOTAL, country, 0)}</TableCell>
                                            </TableRow>
                                            <TableRow key={2}>
                                                <TableCell>{t('AverageAirFlow') + Fmt.getUnitInBracket("cubicMeterOfGasperTime", t)}</TableCell>
                                                <TableCell id="td_CAG-cYearlyConsumptionAirFlowTOTAL">{Fmt.round(cagCalculationsResult.cYearlyConsumptionAirFlowTOTAL, 1)}</TableCell>
                                                <TableCell id="td_CAG-mYearlyConsumptionAirFlowTOTAL">{Fmt.round(cagCalculationsResult.mYearlyConsumptionAirFlowTOTAL, 1)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        }

                        <Grid item>
                            <HeadingComponent value={t('CalculatedYearlyConsumptionByCompressorRoom')} size="h6" />
                        </Grid>
                        {
                            Object.keys(cagCalculationsResult).length > 0 &&
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="spanning table">
                                        <TableHead>
                                            <TableRow>
                                                <TableHeadCells
                                                    model={compRoomCalcsModel}
                                                    propertyList={["no", "cYearlyConsumptionElectricityROOM", "cYearlyConsumptionAirConsumptionROOM", "cYearlyConsumptionAirFlowROOM"]}
                                                    styleList={[{ width: "20%" }, {}, {}, {}]}
                                                    tableName="CalculatedYearlyConsumptionByCompressorRoom"
                                                    data={compRoomCalcs}
                                                    setData={setCompRoomCalcs}
                                                />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {compRoomCalcs.slice(compressorRoomListCurrentPage * compressorRoomListRowsPerPage, compressorRoomListCurrentPage * compressorRoomListRowsPerPage + compressorRoomListRowsPerPage).map((compressorRoom, index) =>
                                                <TableRow key={compressorRoom.no}>
                                                    <TableCell id={"td_CAG-no" + index} width="20%">{compressorRoom.no}</TableCell>
                                                    <TableCell id={"td_CAG-cYearlyConsumptionElectricityROOM" + index}>{Fmt.round(compressorRoom.cYearlyConsumptionElectricityROOM, country, 0)}</TableCell>
                                                    <TableCell id={"td_CAG-cYearlyConsumptionAirConsumptionROOM" + index}>{Fmt.round(compressorRoom.cYearlyConsumptionAirConsumptionROOM, country, 0)}</TableCell>
                                                    <TableCell id={"td_CAG-cYearlyConsumptionAirFlowROOM" + index}>{Fmt.round(compressorRoom.cYearlyConsumptionAirFlowROOM, country, 1)}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    component="div"
                                    count={cagCalculationsResult.compRoomCalcs && cagCalculationsResult.compRoomCalcs.length > 0 ? cagCalculationsResult.compRoomCalcs.length : 0}
                                    rowsPerPage={compressorRoomListRowsPerPage}
                                    page={compressorRoomListCurrentPage}
                                    onPageChange={handleChangeCompressorRoomListCurrentPage}
                                    onRowsPerPageChange={handleChangeCompressorRoomListRowsPerPage}
                                />
                            </Grid>
                        }

                        <Grid item>
                            <HeadingComponent value={t('MeasuredYearlyConsumptionByCompressorRoom')} size="h6" />
                        </Grid>
                        {
                            Object.keys(cagCalculationsResult).length > 0 &&
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="spanning table">
                                        <TableHead>
                                            <TableRow>
                                                <TableHeadCells
                                                    model={compRoomCalcsModel}
                                                    propertyList={["no", "mYearlyConsumptionElectricityROOM", "mYearlyConsumptionAirConsumptionROOM", "mYearlyConsumptionAirFlowROOM"]}
                                                    styleList={[{ width: "20%" }, {}, {}, {}]}
                                                    tableName="MeasuredYearlyConsumptionByCompressorRoom"
                                                    data={compRoomCalcs}
                                                    setData={setCompRoomCalcs}
                                                />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {compRoomCalcs.slice(compressorRoomListCurrentPage * compressorRoomListRowsPerPage, compressorRoomListCurrentPage * compressorRoomListRowsPerPage + compressorRoomListRowsPerPage).map((compressorRoom, index) =>
                                                <TableRow key={compressorRoom.no}>
                                                    <TableCell id={"td_CAG-no" + index}>{compressorRoom.no}</TableCell>
                                                    <TableCell id={"td_CAG-mYearlyConsumptionElectricityROOM" + index}>{Fmt.round(compressorRoom.mYearlyConsumptionElectricityROOM, country, 0)}</TableCell>
                                                    <TableCell id={"td_CAG-mYearlyConsumptionAirConsumptionROOM" + index}>{Fmt.round(compressorRoom.mYearlyConsumptionAirConsumptionROOM, country, 0)}</TableCell>
                                                    <TableCell id={"td_CAG-mYearlyConsumptionAirFlowROOM" + index}>{Fmt.round(compressorRoom.mYearlyConsumptionAirFlowROOM, country, 1)}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                    component="div"
                                    count={cagCalculationsResult.compRoomCalcs && cagCalculationsResult.compRoomCalcs.length > 0 ? cagCalculationsResult.compRoomCalcs.length : 0}
                                    rowsPerPage={compressorRoomListRowsPerPage}
                                    page={compressorRoomListCurrentPage}
                                    onPageChange={handleChangeCompressorRoomListCurrentPage}
                                    onRowsPerPageChange={handleChangeCompressorRoomListRowsPerPage}
                                />
                            </Grid>
                        }

                        <Grid item id="#SpecificPowerOfSystem">
                            <HeadingComponent value={t('SpecificPowerOfSystem')} size="h6" />
                        </Grid>
                        {
                            Object.keys(cagCalculationsResult).length > 0 &&
                            <Grid item className="specific-power-of-system" xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="spanning table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="table-rightborder" width="20%"></TableCell>
                                                <TableCell className="table-rightborder" colSpan={2} align="center">{t('Calculated')}</TableCell>
                                                <TableCell colSpan={2} align="center">{t('Measured')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={0}>
                                                <TableCell className="table-rightborder">{t('SpecificPowerAccordingToTechnicalData')}</TableCell>
                                                <TableCell id="td_CAG-cSpecificPowerTechnDataH">{Fmt.round(cagCalculationsResult.cSpecificPowerTechnDataH, country, 3) + Fmt.getUnitInBracket("powerPerHourPerVolume", t)}</TableCell>
                                                <TableCell className="table-rightborder" id="td_CAG-cSpecificPowerTechnDataMin">{Fmt.round(cagCalculationsResult.cSpecificPowerTechnDataMin, country, 2) + Fmt.getUnitInBracket("powerPerTimePerVolume", t)}</TableCell>
                                                <TableCell id="td_CAG-mSpecificPowerTechnDataH">{Fmt.round(cagCalculationsResult.mSpecificPowerTechnDataH, country, 3) + Fmt.getUnitInBracket("powerPerHourPerVolume", t)}</TableCell>
                                                <TableCell id="td_CAG-mSpecificPowerTechnDataMin">{Fmt.round(cagCalculationsResult.mSpecificPowerTechnDataMin, country, 2) + Fmt.getUnitInBracket("powerPerTimePerVolume", t)}</TableCell>
                                            </TableRow>
                                            <TableRow key={1}>
                                                <TableCell className="table-rightborder">{t('LosesDueToAtmosphericConditions')}</TableCell>
                                                <TableCell className="table-rightborder" id="td_CAG-cSpecificPowerLosesAtmConditions" colSpan={2}>{Fmt.round(cagCalculationsResult.cSpecificPowerLosesAtmConditions, country, 1)} [%]</TableCell>
                                                <TableCell id="td_CAG-mSpecificPowerLosesAtmConditions" colSpan={2}>{Fmt.round(cagCalculationsResult.cSpecificPowerLosesAtmConditions, country, 1)} [%]</TableCell>
                                                {/*was mSpecificPowerLosesAtmConditions*/}
                                            </TableRow>
                                            <TableRow key={2}>
                                                <TableCell className="table-rightborder">{t('SpecificPowerOfTheCompressors')}</TableCell>
                                                <TableCell id="td_CAG-cSpecificPowerCorrectedH">{Fmt.round(cagCalculationsResult.cSpecificPowerCorrectedH, country, 3) + Fmt.getUnitInBracket("powerPerHourPerNm", t)}</TableCell>
                                                <TableCell className="table-rightborder" id="td_CAG-cSpecificPowerCorrectedMin">{Fmt.round(cagCalculationsResult.cSpecificPowerCorrectedMin, country, 2) + Fmt.getUnitInBracket("powerPerMinPerNm", t)}</TableCell>
                                                <TableCell id="td_CAG-mSpecificPowerCorrectedH">{Fmt.round(cagCalculationsResult.mSpecificPowerCorrectedH, country, 3) + Fmt.getUnitInBracket("powerPerHourPerNm", t)} </TableCell>
                                                <TableCell id="td_CAG-mSpecificPowerCorrectedMin">{Fmt.round(cagCalculationsResult.mSpecificPowerCorrectedMin, country, 2) + Fmt.getUnitInBracket("powerPerMinPerNm", t)} </TableCell>
                                            </TableRow>
                                            <TableRow key={3}>
                                                <TableCell className="table-rightborder">{t('LosesDueToOperations')}</TableCell>
                                                <TableCell
                                                    id="td_CAG-cSpecificPowerLosesOperation"
                                                    colSpan={2}
                                                    className={Fmt.round(cagCalculationsResult.cSpecificPowerLosesOperation, country, 1) < 0 ? "text-color-red table-rightborder" : "table-rightborder"}
                                                >
                                                    {Fmt.round(cagCalculationsResult.cSpecificPowerLosesOperation, country, 1)} [%]
                                                </TableCell>
                                                <TableCell
                                                    id="td_CAG-mSpecificPowerLosesOperation"
                                                    colSpan={2}
                                                    className={Fmt.round(cagCalculationsResult.mSpecificPowerLosesOperation, country, 1) < 0 ? "text-color-red" : ""}
                                                >
                                                    {Fmt.round(cagCalculationsResult.mSpecificPowerLosesOperation, country, 1)} [%]
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={4}>
                                                <TableCell className="table-rightborder">{t('SpecificPowerOfTheSystem')}</TableCell>
                                                <TableCell id="td_CAG-cSpecificPowerSystemH">{Fmt.round(cagCalculationsResult.cSpecificPowerSystemh, country, 3) + Fmt.getUnitInBracket("powerPerHourPerNm", t)}</TableCell>
                                                <TableCell className="table-rightborder" id="td_CAG-cSpecificPowerSystemMin">{Fmt.round(cagCalculationsResult.cSpecificPowerSystemMin, country, 2) + Fmt.getUnitInBracket("powerPerMinPerNm", t)}</TableCell>
                                                <TableCell id="td_CAG-mSpecificPowerSystemH">{Fmt.round(cagCalculationsResult.mSpecificPowerSystemH, country, 3) + Fmt.getUnitInBracket("powerPerHourPerNm", t)} </TableCell>
                                                <TableCell id="td_CAG-mSpecificPowerSystemMin">{Fmt.round(cagCalculationsResult.mSpecificPowerSystemMin, country, 2) + Fmt.getUnitInBracket("powerPerMinPerNm", t)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        }
                    </React.Fragment>
                }

                <Grid item id="#CostsInformation">
                    <HeadingComponent value={t('CostsPerUnit')} size="h6" />
                </Grid>
                {cagCalculationsResult &&
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="spanning table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20%"></TableCell>
                                        <TableCell>{t('EnergyCost') + Fmt.getUnitWithCurrency("powerPerHour", t)}</TableCell>
                                        <TableCell>{t('CompressedAirCosts') + Fmt.getUnitWithCurrency("cubicMeterOfGas", t)}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={0}>
                                        <TableCell>{t('Electricity')}</TableCell>
                                        <TableCell id="td_CAG-costPerKwh">{Fmt.round(cagCalculationsResult.costPerKwh, country, 2)}</TableCell>
                                        <TableCell id="td_CAG-rCostElectricityPerm3">{Fmt.round(cagCalculationsResult.rCostElectricityPerm3, country, 3)}</TableCell>
                                    </TableRow>
                                    <TableRow key={1}>
                                        <TableCell className="font-bold">{t('TotalCosts')}</TableCell>
                                        <TableCell id="td_CAG-rCostElectricityPerKwhTOTAL" className="font-bold"><div className="hidden">{Fmt.round(cagCalculationsResult.rCostElectricityPerKwhTOTAL, country, 2)}</div></TableCell>
                                        <Tooltip title={Fmt.round(cagCalculationsResult.rCostPerm3, country, 7)} placement="left">
                                            <TableCell id="td_CAG-rCostPerm3" className="font-bold">
                                                {Fmt.round(cagCalculationsResult.rCostPerm3, country, 3)}
                                            </TableCell>
                                        </Tooltip>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography>
                            * {t("InclusinveElectricityMaintenanceInvestmentCosts")}
                        </Typography>
                    </Grid>
                }
                {isCagExplicitlySelected &&
                    <React.Fragment>
                        <Grid item>
                            <HeadingComponent value={t('CostPerYear')} size="h6" />
                        </Grid>
                        {cagCalculationsResult &&
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="spanning table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="20%">{t('CO2') + Fmt.getUnitInBracket("co2Wheight", t)} </TableCell>
                                                <TableCell>{t('EnergyCost') + Fmt.getCurrency()}</TableCell>
                                                <TableCell>{t('AcquisitionAndMaintenanceCosts') + Fmt.getCurrency()}</TableCell>
                                                <TableCell className="font-bold">{t('TotalCosts') + Fmt.getCurrency()}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={0}>
                                                <TableCell id="td_CAG-rYearlyConsumptionCO2TOTAL">{Fmt.round(cagCalculationsResult.rYearlyConsumptionCO2TOTAL, country, 0)}</TableCell>
                                                <TableCell id="td_CAG-rYearlyConsumptionElectricityCosts">{Fmt.round(cagCalculationsResult.rYearlyConsumptionElectricityCosts, country, 0)}</TableCell>
                                                <TableCell id="td_CAG-rYearlyConsumption25Percentage">{Fmt.round(cagCalculationsResult.rYearlyConsumption25Percentage, country, 0)}</TableCell>
                                                <TableCell id="td_CAG-rYearlyConsumptionAirConsumptionCosts" className="font-bold">{Fmt.round(cagCalculationsResult.rYearlyConsumptionAirConsumptionCosts, country, 0)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Typography>
                                    * {t("InclusinveElectricityMaintenanceInvestmentCosts")}
                                </Typography>
                                <Typography>
                                    ** 25% {t('OfTheTotalCosts')}
                                </Typography>
                            </Grid>
                        }
                    </React.Fragment>
                }

                <Grid item id="#AirConsumptionProfile">
                    <HeadingComponent value={t('AirConsumptionProfile')} size="h6" />
                </Grid>

                <Grid item xs={12}>
                    {
                        Object.keys(cagCalculationsResult).length > 0 &&
                        <AirProfileChart data={cagCalculationsResult} ></AirProfileChart>
                    }
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize disabled={denyEdit} type="text" value={compressedAirGenerationModel.comment.value} minRows="5"
                        onChange={e => {
                            updateModelHandler("comment", e.target.value)
                        }}
                        id="textarea_AQ-comment"
                    />
                </Grid>
                <Grid item>
                    <ImageViewer disabled={denyEdit} groupId={ImageBlobConstants.CompressedAirGen} />
                </Grid>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item><ButtonComponent id='CAG-cancel' value={t('Cancel')} color="secondary" onChange={handleCancelClick}></ButtonComponent></Grid>
                        {!denyEdit && <Grid item><ButtonComponent id='CAG-save' value={t('Save')} color="primary" onChange={postForm}></ButtonComponent></Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );

}
