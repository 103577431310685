import React, { useState, useEffect } from 'react'
import { Route, RouteProps } from "react-router-dom";
import { ErrorPage } from '../base/ErrorPage'
import { UserStore } from '../../store'
import InitializeMessage from '../InitializeMessage/InitializeMessage';
import LoginMessage from '../LoginMessage/LoginMessage';
import { HasUserRole } from '../../helper/AuthorizationHelper/AuthHelper';
import { useAuth } from 'react-oidc-context';
import { getLocale } from '../../helper/CountryLanguages'

function EcondaPageInit(globalContentId: string) {
    const { country, lng } = getLocale();

    const pathParts = window.location.pathname.split('/').filter(Boolean);
    const hostname = window.location.hostname;

    let defaultCountry = 'us';
    let defaultLng = 'en';

    if (hostname.includes('.cn')) {
        defaultCountry = 'cn';
        defaultLng = 'zh';
    }

    const pageIdentifier = pathParts.slice().join('-').replace(/\d+/g, 'some_id').toLowerCase();

    window.fwaLayer.page.globalContentId = globalContentId;
    window.fwaLayer.page.contentLabel = pageIdentifier.length > 0 ? pageIdentifier : "home-page";
    window.fwaLayer.global.countryId = country.length > 0 ? country.toLowerCase() : defaultCountry;
    window.fwaLayer.global.languageId = lng.length > 0 ? lng.toLowerCase() : defaultLng;
}

interface PrivateRouteProps extends RouteProps {
    component: any;
    allowedRoles?: string[];
    globalContentId: string;

    denyEdit?: boolean;
    showContent?: boolean;
    
    handleSideBar?: (show: boolean, iconHide?: boolean) => void;
    setIsProjectListActive?: React.Dispatch<React.SetStateAction<boolean>>;
}


export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, denyEdit, showContent, handleSideBar, allowedRoles, globalContentId,setIsProjectListActive, ...rest }) => {
    const [user, setUser] = useState({});
    const [isActive, setIsActive] = useState(false);
    const auth = useAuth();


    useEffect(() => {
        const userStore = UserStore.user.subscribe(user => setUser(user));
        const userIsActiveStore = UserStore.userLoggedIn.subscribe(userLoggedIn => setIsActive(userLoggedIn));
        UserStore.loginUser(auth);
        return () => {
            userIsActiveStore.unsubscribe();
            userStore.unsubscribe();
        }
    }, [])

    useEffect(() => {
        EcondaPageInit(globalContentId);
    }, [globalContentId]);

    window.fwaLayer?.send?.({ eventType: "new-page", newPage: {} });

    return (
        <Route
            {...rest}
            render={() => (
                !isActive && <LoginMessage />
                || Object.keys(user).length == 0 && <InitializeMessage title="GettingUserRights"></InitializeMessage>
                || !HasUserRole(allowedRoles, user) && <ErrorPage errorCode="401" errorMessage="Unauthorized" />
                || <Component
                    {...rest}
                    denyEdit={denyEdit}
                    handleSideBar={handleSideBar}
                    showContent={showContent}
                    setIsProjectListActive={setIsProjectListActive}
                />
            )}
        />
    )
}

interface PublicRouteProps extends RouteProps {
    globalContentId: string;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({ globalContentId, ...rest }) => {
    useEffect(() => {
        EcondaPageInit(globalContentId);
    }, [globalContentId]);

    window.fwaLayer?.send?.({ eventType: "new-page", newPage: {} });
    return (<Route {...rest} />);
};