import React, { useState } from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Delete, AddCircleOutline, VerticalAlignTop } from '@mui/icons-material';
import FilterComponent from './FilterComponent'
import FilterIconComponent from './FilterIconComponent';
import { useTranslation } from "react-i18next";
import { SimpleButton } from '../';
import "./TableHeadingContent.css"

export default function TableHeadingContent(props) {
    //filterData - original backup data
    //defaultData - current data with all filters applied
    //onFilterFunction - update defaultData
    const { exportButton, clickMassEdit, copyExecutorInquiryLink, clickMultiRepaired, clickImport, clickDelete, clickAdd, filterModel, NotShowAddDelete, tableId, denyEdit } = props;

    const { t } = useTranslation();

    const [chipsArray, setChipsArray] = useState([]);

    const handleChipArray = (value) => setChipsArray(value);

    return (
        <div className="TableHeadingContent">
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    {filterModel &&
                        <FilterComponent {...props} textFilterFields={filterModel.textFilter} handleChipArray={handleChipArray} chipsArray={chipsArray} />
                    }
                </Grid>
                <Grid item>
                    <Grid container>
                        {exportButton != undefined &&
                            exportButton
                        }
                        {clickMassEdit != undefined && !denyEdit &&
                            <SimpleButton onClick={clickMassEdit}>
                                {t("MassEdit")}
                            </SimpleButton>
                        }
                        {copyExecutorInquiryLink != undefined && !denyEdit &&
                            <SimpleButton onClick={copyExecutorInquiryLink} id="copy-executor-inquiry-link">
                                {t("CopyExecutorSpecificInquiryLink")}
                            </SimpleButton>
                        }
                        {clickMultiRepaired != undefined && !denyEdit &&
                            <SimpleButton onClick={clickMultiRepaired}>
                                {t("Repaired")}
                            </SimpleButton>
                        }
                        {clickImport != undefined && !denyEdit &&
                            <Tooltip title={t("Import")}>
                                <IconButton onClick={clickImport} id={`btn_${tableId}_import`} size="large">
                                    <VerticalAlignTop />
                                </IconButton>
                            </Tooltip>
                        }
                        {filterModel && filterModel.iconFilter.length > 0 &&
                            <FilterIconComponent
                                {...props}
                                iconFilterFields={filterModel.iconFilter}
                                handleChipArray={handleChipArray}
                                chipsArray={chipsArray}
                            />
                        }
                        {denyEdit || NotShowAddDelete ? "" :
                            <React.Fragment>
                                {clickDelete &&
                                    <Tooltip title={t("Delete")}>
                                        <IconButton onClick={clickDelete} id={`btn_${tableId}_delete`} size="large">
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {clickAdd &&
                                    <Tooltip title={t("Add")}>
                                        <IconButton id={`btn_${tableId}_add`} onClick={clickAdd} size="large">
                                            <AddCircleOutline />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
