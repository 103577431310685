// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace } from "../../../../helper/Validator";
import { ModelParam, Translate } from "../../../Types/Types";

export interface IEmailTemplate {
    id: number,
    emailDescription: string,
    emailSubject: string,
    emailBody: string,
}

export interface IEmailTemplateModel {
    id: ModelParam<number>,
    emailDescription: ModelParam<string>,
    emailSubject: ModelParam<string>,
    emailBody: ModelParam<string>,
}

export const EmailTemplateFactory = (t: Translate) : IEmailTemplateModel=> ({
    id: { label: "", idkey: "", value: 0, isValid: true, validators: [], validationError: "" },
    emailDescription: { label: t("EmailDescription"), value: "", isValid: true, validators: [checkIsNotNullOrWhitespace], idkey: "", validationError: "" },
    emailSubject: { label: t("EmailSubject"), value: "", isValid: true, validators: [checkIsNotNullOrWhitespace], idkey: "", validationError: "" },
    emailBody: { label: t("EmailBody"), value: "", isValid: true, validators: [checkIsNotNullOrWhitespace], idkey: "", validationError: "" },
});
